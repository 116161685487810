// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "jquery"

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap'
require("stylesheets/application.scss")


Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(function() {
  console.log("star!");
  const carouselContainer = document.querySelector(".carousel-container");
  const listImageArea = carouselContainer.querySelector(".next-list");
  const listOfImages = listImageArea.querySelectorAll("img");
  const currentImage = carouselContainer.querySelector(".current-image");
  const arrowLeft = carouselContainer.querySelector(".arrow-left");
  const arrowRight = carouselContainer.querySelector(".arrow-right");

  function styleList() {
      if (listImageArea.scrollWidth == listImageArea.offsetWidth) {
          listImageArea.style.justifyContent = "center";
      } else {
          listImageArea.style.justifyContent = "flex-start";
      }
  }

  function goToRight() {
      var current = listImageArea.querySelector(".current-image-list");
      current.parentElement.nextElementSibling.children[0].classList.add(
          "current-image-list"
      );
      current.classList.remove("current-image-list");
      current = listImageArea.querySelector(".current-image-list");
      listImageArea.scrollLeft = current.offsetLeft;
      currentImage.attributes.src.value = current.attributes.src.value;
      currentImage.classList.add("slideInFromRight");
      setTimeout(() => {
          currentImage.classList.remove("slideInFromRight");
      }, 500);
  }

  function goToLeft() {
      var current = listImageArea.querySelector(".current-image-list");
      current.parentElement.previousElementSibling.children[0].classList.add(
          "current-image-list"
      );
      current.classList.remove("current-image-list");
      current = listImageArea.querySelector(".current-image-list");
      listImageArea.scrollLeft = current.offsetLeft;
      currentImage.attributes.src.value = current.attributes.src.value;
      currentImage.classList.add("slideInFromLeft");
      setTimeout(() => {
          currentImage.classList.remove("slideInFromLeft");
      }, 500);
  }

  function changeCurrentImage(newImage) {
    currentImage.classList.add("fadeIn");
    setTimeout(() => {
        currentImage.classList.remove("fadeIn");
    }, 500);
    currentImage.attributes.src.value = this.attributes.src.value;
    listOfImages.forEach((image) => {
        image.classList.remove("current-image-list");
    });
    this.classList.add("current-image-list");
  }

  styleList();

  arrowLeft.addEventListener("click", goToLeft);
  arrowRight.addEventListener("click", goToRight);

  window.addEventListener("resize", (e) => {
    styleList();
  });

  (function() {
    if (typeof NodeList.prototype.forEach === "function") return false;
    NodeList.prototype.forEach = Array.prototype.forEach;
  })();

  listOfImages.forEach((image) => {
    image.addEventListener("click", changeCurrentImage);
  });
  console.log( "ready!" );
});
